import React from 'react';
import twemoji from 'twemoji';

import { Country } from 'types';

import * as styles from './index.module.scss';

interface TableHeaderItemProps {
  country: Country;
}

export const TableHeaderItem: React.FC<TableHeaderItemProps> = ({
  country,
}) => {
  return (
    <>
      {country.countryId}
      <span
        className={styles.countryFlag}
        dangerouslySetInnerHTML={{
          __html: twemoji.parse(country.flag, {
            className: styles.countryFlagImage,
            ext: '.svg',
            folder: 'svg',
          }),
        }}
      />
    </>
  );
};
