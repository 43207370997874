import { useState, useCallback, useEffect } from 'react';

export enum Breakpoint {
  medium = 'medium',
  large = 'large',
  extraLarge = 'extraLarge',
}

// update src/styles/helpers/_mixins.scss
export const breakpointsMinWidths = {
  medium: 768,
  large: 1024,
  extraLarge: 1440,
};

export const useBreakpoint = (): Breakpoint | null => {
  const [currentBreakpoint, setCurrentBreakpoint] = useState<Breakpoint | null>(
    null
  );
  const updateBreakpoint = useCallback(() => {
    const { extraLarge, large, medium } = breakpointsMinWidths;

    if (window.innerWidth < medium) {
      currentBreakpoint && setCurrentBreakpoint(null);
    } else if (window.innerWidth < large) {
      currentBreakpoint !== Breakpoint.medium &&
        setCurrentBreakpoint(Breakpoint.medium);
    } else if (window.innerWidth < extraLarge) {
      currentBreakpoint !== Breakpoint.large &&
        setCurrentBreakpoint(Breakpoint.large);
    } else {
      currentBreakpoint !== Breakpoint.extraLarge &&
        setCurrentBreakpoint(Breakpoint.extraLarge);
    }
  }, [currentBreakpoint, setCurrentBreakpoint]);

  useEffect(() => {
    updateBreakpoint();
    window.addEventListener('resize', updateBreakpoint);
    return (): void => window.removeEventListener('resize', updateBreakpoint);
  }, [updateBreakpoint]);

  return currentBreakpoint;
};
