import React, { useCallback, ChangeEvent, useRef } from 'react';
import twemoji from 'twemoji';

import { Country } from 'types';

import * as styles from './index.module.scss';

interface TableHeaderSelectItemProps {
  countries: Country[];
  countryId: string;
  selectedIndex: number;
  updateSelectedCountryIds: (countryId: string, index: number) => void;
}

export const TableHeaderSelectItem: React.FC<TableHeaderSelectItemProps> = ({
  countries,
  countryId,
  selectedIndex,
  updateSelectedCountryIds,
}) => {
  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      updateSelectedCountryIds(event.target.value, selectedIndex);
    },
    [selectedIndex, updateSelectedCountryIds]
  );
  const country = countries.find((country) => country.countryId === countryId);
  const sortedCountries = [...countries].sort((a, b) =>
    a.name > b.name ? 1 : -1
  );

  return (
    <div className={styles.selectWrapper}>
      <div className={styles.selectOverlay}>
        <span
          className={styles.countryFlag}
          dangerouslySetInnerHTML={{
            __html: twemoji.parse(country!.flag, {
              className: styles.countryFlagImage,
              ext: '.svg',
              folder: 'svg',
            }),
          }}
        />
        <svg
          className={styles.selectIcon}
          width="8"
          height="13"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M4 0l4 6H0zm0 13l4-5H0z" fillRule="evenodd" />
        </svg>
      </div>

      <select
        className={styles.select}
        defaultValue={countryId}
        onChange={onChange}
      >
        {sortedCountries.map((country) => (
          <option key={country.countryId} value={country.countryId}>
            {country.flag}
            {country.name}
          </option>
        ))}
      </select>
    </div>
  );
};
