import React, { useCallback, useRef } from 'react';
import cx from 'classnames';

import { Country } from 'types';
import { useBreakpoint } from 'hooks/useBreakpoint';
import { useStickyHeader } from 'hooks/useStickyHeader';
import { Grid } from 'components/Grid';
import { GridItem } from 'components/GridItem';
import { TableHeaderItem } from 'components/TableHeaderItem';
import { TableHeaderSelectItem } from 'components/TableHeaderSelectItem';

import * as styles from './index.module.scss';

interface TableHeaderProps {
  countries: Country[];
  selectedCountryIds: string[];
  updateSelectedCountryIds: (countryId: string, index: number) => void;
}

export const TableHeader: React.FC<TableHeaderProps> = ({
  countries,
  selectedCountryIds,
  updateSelectedCountryIds,
}) => {
  const currentBreakpoint = useBreakpoint();
  const headerRef = useRef<HTMLDivElement>(null);
  const isSticky = useStickyHeader(headerRef);

  let smallColIndex = 1;
  let mediumColIndex = 1;

  return (
    <div className={styles.headerWrapper} ref={headerRef}>
      <Grid className={cx({ [styles.headerFixed]: isSticky })}>
        {currentBreakpoint
          ? countries.map((country) => {
              mediumColIndex += 1;
              return (
                <GridItem
                  key={country.countryId}
                  className={styles.th}
                  colM={`${mediumColIndex - 1}${mediumColIndex}`}
                >
                  <TableHeaderItem country={country} />
                </GridItem>
              );
            })
          : selectedCountryIds.map((countryId, index) => {
              smallColIndex += 2;
              return (
                <GridItem
                  key={index}
                  className={styles.th}
                  colS={`${smallColIndex - 2}${smallColIndex}`}
                >
                  <TableHeaderSelectItem
                    countries={countries}
                    countryId={countryId}
                    selectedIndex={index}
                    updateSelectedCountryIds={updateSelectedCountryIds}
                  />
                </GridItem>
              );
            })}
      </Grid>
    </div>
  );
};
