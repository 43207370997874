import { RefObject, useCallback, useEffect, useState } from 'react';

export const useStickyHeader = (headerRef: RefObject<HTMLDivElement>) => {
  const [isSticky, setIsSticky] = useState(false);
  const onScroll = useCallback(() => {
    if (!headerRef.current) {
      return;
    }

    const headerTop = headerRef.current.getBoundingClientRect().top;

    if (!isSticky && headerTop < 0) {
      setIsSticky(true);
    } else if (isSticky && headerTop >= 0) {
      setIsSticky(false);
    }
  }, [headerRef, isSticky]);

  useEffect(() => {
    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [onScroll]);

  return isSticky;
};
