import { useSiteData } from './useSiteData';

export const useTableData = () => {
  const { isLoading, data: countries } = useSiteData();
  const defaultCountries = countries.reduce<string[]>((defaults, country) => {
    if (country.default) {
      defaults.push(country.countryId);
    }

    return defaults;
  }, []);

  return { countries, defaultCountries, isLoading };
};
