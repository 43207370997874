import React from 'react';

import { Grid } from 'components/Grid';
import { GridItem } from 'components/GridItem';

import * as styles from './index.module.scss';

export const Footer: React.FC = () => (
  <footer className={styles.footer}>
    <Grid>
      <GridItem colS="15" colM="15" colL="14" colXl="13">
        The Convert Size wants to help you determine the best shoe fit. Convert
        Size is available for shoe size from the United States, United Kingdom,
        Brazil, Japan, Australia, and China for men and women.
      </GridItem>
      <GridItem
        className={styles.signature}
        colS="15"
        colM="15"
        colL="14"
        colXl="13"
      >
        Made by Invisible Guys
      </GridItem>
    </Grid>
  </footer>
);
