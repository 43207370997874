import React, { useState, useCallback } from 'react';

import { Country, HashValue } from 'types';
import { TableHeader } from 'components/TableHeader';
import { TableBody } from 'components/TableBody';

interface TableProps {
  countries: Country[];
  currentHash: string;
  defaultCountries: string[];
  isLoading: boolean;
}

export const Table: React.FC<TableProps> = ({
  countries,
  currentHash,
  defaultCountries,
  isLoading,
}) => {
  const [selectedCountryIds, setSelectedCountryIds] = useState(
    defaultCountries
  );
  const updateSelectedCountryIds = useCallback(
    (id, index) => {
      const newList = [...selectedCountryIds];
      newList[index] = id;

      setSelectedCountryIds(newList);
    },
    [selectedCountryIds, setSelectedCountryIds]
  );
  const sizesKey = currentHash === HashValue.men ? 'sm' : 'sw';

  return (
    <>
      <TableHeader
        countries={countries}
        selectedCountryIds={selectedCountryIds}
        updateSelectedCountryIds={updateSelectedCountryIds}
      />
      <TableBody
        countries={countries}
        isLoading={isLoading}
        selectedCountryIds={selectedCountryIds}
        sizesKey={sizesKey}
      />
    </>
  );
};
