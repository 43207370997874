import { useState, useEffect } from 'react';
import drive from 'drive-db';
import { graphql, useStaticQuery } from 'gatsby';

import { ApiCountry, Country } from 'types';

export const useSiteData = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [data, setData] = useState<Country[]>();
  const {
    allCountry: { nodes },
  }: {
    allCountry: { nodes: Country[] };
  } = useStaticQuery(graphql`
    query MyQuery {
      allCountry {
        nodes {
          countryId
          default
          flag
          name
          sm
          sw
        }
      }
    }
  `);

  useEffect(() => {
    setIsLoading(true);

    drive({
      sheet: '1Eyremrp2343z0f47OD0jnt152SnjD7geijL9caIdbKA',
      tab: '2',
    }).then((countries: ApiCountry[]) => {
      setData(
        countries.map((country) => ({
          countryId: country.code,
          default: !!country.default,
          flag: country.flag,
          name: country.name,
          sm: country.sm.split(','),
          sw: country.sw.split(','),
        }))
      );
      setIsLoading(false);
    });
  }, []);

  return {
    isLoading,
    data: data || nodes,
  };
};
