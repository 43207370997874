import React from 'react';

import { useTableData } from 'hooks/useTableData';
import { Footer } from 'components/Footer';
import { Header } from 'components/Header';
import { HeadSeo } from 'components/HeadSeo';
import { Table } from 'components/Table';

import 'styles/index.scss';

interface IndexPageProps {
  location: {
    hash: string;
  };
}

const IndexPage: React.FC<IndexPageProps> = ({ location: { hash } }) => {
  const { countries, defaultCountries, isLoading } = useTableData();
  const currentHash = hash || '#men-sizes';

  return (
    <>
      <HeadSeo />
      <Header currentHash={currentHash} />
      <Table
        countries={countries}
        currentHash={currentHash}
        defaultCountries={defaultCountries}
        isLoading={isLoading}
      />
      <Footer />
    </>
  );
};

export default IndexPage;
