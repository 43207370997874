import React from 'react';
import cx from 'classnames';

import { Country } from 'types';
import { Grid } from 'components/Grid';

import * as styles from './index.module.scss';
import { GridItem } from 'components/GridItem';
import { useBreakpoint } from 'hooks/useBreakpoint';

interface TableBodyProps {
  countries: Country[];
  isLoading: boolean;
  selectedCountryIds: string[];
  sizesKey: keyof Pick<Country, 'sm' | 'sw'>;
}

export const TableBody: React.FC<TableBodyProps> = ({
  countries,
  isLoading,
  selectedCountryIds,
  sizesKey,
}) => {
  const currentBreakpoint = useBreakpoint();
  const countriesToUse = currentBreakpoint
    ? countries
    : selectedCountryIds.map((selectedCountryId) => {
        return countries.find(
          (country) => country.countryId === selectedCountryId
        );
      });

  let smallColIndex = 1;
  let mediumColIndex = 1;

  return (
    <div className={styles.wrapper}>
      {isLoading && <div className={styles.loading}></div>}
      <Grid>
        {countriesToUse.map((country, colIndex) => {
          smallColIndex += 2;
          mediumColIndex += 1;

          return (
            <GridItem
              key={colIndex}
              colS={`${smallColIndex - 2}${smallColIndex}`}
              colM={`${mediumColIndex - 1}${mediumColIndex}`}
            >
              {country![sizesKey].map((size, lineIndex) => (
                <div
                  key={lineIndex}
                  className={cx(styles.line, {
                    [styles.lineEffects]: colIndex === 0,
                  })}
                >
                  <div className={styles.lineContent}>{size}</div>
                </div>
              ))}
            </GridItem>
          );
        })}
      </Grid>
    </div>
  );
};
