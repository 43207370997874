export interface ApiCountry {
  code: string;
  default: string;
  flag: string;
  name: string;
  sm: string;
  sw: string;
}

export interface Country {
  countryId: string;
  default: boolean;
  flag: string;
  name: string;
  sm: string[];
  sw: string[];
}

export enum HashValue {
  men = '#men-sizes',
  women = '#women-sizes',
}
