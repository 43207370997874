import React from 'react';
import cx from 'classnames';

import { HashValue } from 'types';
import { Grid } from 'components/Grid';
import { GridItem } from 'components/GridItem';

import * as styles from './index.module.scss';

interface HeaderProps {
  currentHash: string;
}

export const Header: React.FC<HeaderProps> = ({ currentHash }) => {
  return (
    <header className={styles.header}>
      <Grid>
        <GridItem colS="24" colM="13">
          <h1>Shoe Sizes</h1>
        </GridItem>
        <GridItem colS="15" colM="57">
          <nav className={styles.nav}>
            <Grid>
              <GridItem colS="13" colM="14">
                <a
                  href="#men-sizes"
                  className={cx(styles.link, {
                    [styles.linkActive]: currentHash === HashValue.men,
                  })}
                >
                  Men
                </a>
              </GridItem>
              <GridItem colS="35" colM="47">
                <a
                  href="#women-sizes"
                  className={cx(styles.link, {
                    [styles.linkActive]: currentHash === HashValue.women,
                  })}
                >
                  Women
                </a>
              </GridItem>
              <div
                className={cx(styles.selectedBackground, {
                  [styles.selectedBackgroundRight]:
                    currentHash === HashValue.women,
                })}
              ></div>
            </Grid>
          </nav>
        </GridItem>
      </Grid>
    </header>
  );
};
